html,
body {
    background: #ffffff;
    font-size: 0.28rem;
    width: 100%;
    overflow-x: hidden;
    color: #48285e;
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    list-style: none;
    // touch-action: none;
}
.box {
    display: flex;
    display: -webkit-flex;
}
.flex {
    flex: 1;
    -webkit-flex: 1;
    word-break: break-all;
}
.box-origin-v {
    flex-direction: column;
}
.box-pack-c {
    align-items: center;
}
.box-pack-b {
    align-items: flex-end;
}
.box-align-c {
    justify-content: center;
}
.box-align-a {
    justify-content: space-around;
}
.box-align-j {
    justify-content: space-between;
}
.box-align-r {
    justify-content: flex-end;
}
